<template>
  <ui-fields-select
    label="Mood"
    :options="moods"
    name="mood_id"
    :loading="pending"
    class="grid items-end"
  >
    <template #selected-option="item">
      <ui-parts-mood-status :mood="item" />
    </template>
    <template #option="item">
      <ui-parts-mood-status :mood="item" />
    </template>
  </ui-fields-select>
</template>

<script lang="ts" setup>
  const { moods, pending } = await useGetMoods();
</script>
