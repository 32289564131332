<template>
  <VDatePicker
    v-model="value"
    :popover="false"
    :locale="locale"
    :model-modifiers="{ string: true }"
    :masks="{ modelValue: mask }"
    :min-date="minDate"
    :max-date="maxDate"
  >
    <template #default="{ togglePopover, inputValue, inputEvents }">
      <ui-fields-wrap
        :label="label"
        :has-value="hasValue"
        :filed-wrap-class="filedWrapClass"
        :label-two="labelTwo"
        :icon-right="iconRight"
        :icon-left="iconLeft"
        :on-click-right-icon="onClickRightIcon"
        :on-click-left-icon="onClickLeftIcon"
        :required="required"
        :input-id="inputId"
        :hint="hint"
        :disabled="disabled"
        :error="errorMessage"
        :autofocus="autofocus"
      >
        <template #default="{ inputId, setRef, inputClasses, onFocus, onBlur }">
          <input
            :id="inputId"
            :ref="setRef"
            :value="inputValue"
            :name="name"
            :disabled="disabled"
            :placeholder="placeholder || ''"
            :class="inputClasses"
            v-on="inputEvents"
            @blur="
              onBlur();
              togglePopover(false);
            "
            @focus="
              onFocus();
              togglePopover(true);
            "
          />
        </template>
      </ui-fields-wrap>
    </template>
  </VDatePicker>
</template>

<script setup lang="ts">
  type Props = {
    mask?: string;
    minDate?: Date | string;
    maxDate?: Date | string;
  } & TextFieldProps;

  const props = withDefaults(defineProps<Props>(), {
    name: props => props.name || '',
    type: 'text',
    mask: 'YYYY-MM-DD'
  });

  const { locale } = useI18n();

  const { value, errorMessage } = useField(() => props.name || '', undefined, {
    initialValue: props.modelValue,
    syncVModel: props.syncVModel,
    controlled: !props.enabledControlled
  });

  const hasValue = computed(() => !!value.value);
</script>
